import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import HomeSlider from "../components/addon/homeSlider"
import ParagraphBodyDynamicTab from "../components/pageSections/paragraphBodyDynamicTab"

const ShortStayService = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;

    const[bannerData, aboutData, bookingAdmissionsData, instructionsData, preAdmissionData, admissionData, hospitalTourData] = data
    
    // bannerSection
    const bannerImg = typeof (bannerData?.relationships?.components[0]?.relationships?.bannerImage) !== "undefined" ? bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url : ""
    const mobileBannerImg = bannerData?.relationships?.components?.[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof (bannerData?.relationships?.components[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components[0]?.bannerImage?.alt.length > 0 ? bannerData?.relationships?.components[0]?.bannerImage?.alt : "short-stay-service"
    // aboutSection
    const aboutTitle = aboutData?.relationships?.components[0]?.field_title || ''
    const aboutDescription = aboutData?.relationships?.components[0]?.text?.processed || ''
    // bookingAdmissionsSection
    const bookingAdmissionsTitle = bookingAdmissionsData?.relationships?.components[0]?.field_title || ''
    const bookingAdmissionsDescription = bookingAdmissionsData?.relationships?.components[0]?.text?.processed || ''
    // instructionsSection
    const instructionsTitle = instructionsData?.name || ''
    const instructionsDescription = instructionsData?.relationships?.components[0]?.htmlText?.processed || ''
    const instructionsArr = instructionsData?.relationships?.components || []
    instructionsArr.shift()
    // preAdmissionSection
    const preAdmissionTitle = preAdmissionData?.field_title || ''
    const preAdmissionArr = preAdmissionData?.relationships?.field_components || []
    // admissionSection
    const admissionTitle = admissionData?.field_title || ''
    const admissionArr = admissionData?.relationships?.field_components || []
    // hospitalTourSection
    const hospitalTourTitle = hospitalTourData?.name || ''
    const hospitalTourArr = hospitalTourData?.relationships?.components || []

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/homepage.css", "/assets/css/short-stay-services.css","/assets/css/about-through-the-ages.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section">
                      <picture className="banner_img">
                        <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                        <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                      </picture>
                    </section>
                    <section className="section_bg about-ages short-stay-section">
                      <div className="container">
                        <p className="booking-title">Call For Booking : <a href="tel:022 2444 7500">022 2444 7500</a> Ext. <a href="tel:022 2444 8276">8276</a> / <a href="tel:022 2444 3404">3404</a></p>
                        <div className="para_text pb-4">
                          <h1>{aboutTitle}</h1>
                          <div dangerouslySetInnerHTML={{ __html: aboutDescription }} />
                        </div>
                        <div className="pb-section border-top">
                          <div className="para_text">
                            <div className="row py-4 d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row">
                              <div className="col-12">
                                <h2>{bookingAdmissionsTitle}</h2>
                              </div>   
                            </div>          
                            <div dangerouslySetInnerHTML={{ __html: bookingAdmissionsDescription }} />
                            <h3 className="h_small">{instructionsTitle}</h3>
                            <div dangerouslySetInnerHTML={{ __html: instructionsDescription }} />
                          </div>
                          <div className="three_cards_section short-stay-cards">
                            <div className="row">
                              {instructionsArr?.length 
                              ?  instructionsArr.map(el => {
                                let cardImg = el?.relationships?.image?.uri?.url ? el?.relationships?.image?.uri?.url : ''
                                let cardImgAlt = el?.image?.alt
                                return (
                                  <div className="col-lg-4 col-md-6" key={el?.id}>
                                    <div className="card"> 
                                      <img src={cardImg} alt={cardImgAlt} />
                                      <div className="card-body">
                                        <h3>{el?.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                              : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container pb-section">
                        <h2 className="pb-4">{preAdmissionTitle}</h2>
                        <ParagraphBodyDynamicTab content={preAdmissionArr} />
                      </div>
                      <div className="container pb-section">
                        <h2 className="pb-4">{admissionTitle}</h2>
                        <ParagraphBodyDynamicTab content={admissionArr} />
                      </div>
                      <div className="hp_gallery sss-gallery overflow-hidden">
                        <div className="container">
                          <h2 className="pb-4">{hospitalTourTitle}</h2>
                          <div className="tab-content mt-3">
                          <div className="tab-pane active" id="h_virtual_tour" role="tabpanel" aria-labelledby="h_virtual_tour">
                            {hospitalTourArr?.length && 
                              <HomeSlider data={hospitalTourArr} />
                            }
                            <p className="text-center"><a href="javascript:;" className="btn btn-primary mt-4">View all</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query shortStayService {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/short-stay-service$/"}}}) {

    edges {

      node {

        id

       title

        path {

          alias

        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatag_normalized {

          attributes {

            content

            name
            property

          }

        }
        metatags_on_page: field_meta_tags{
          canonical_url
        }
        relationships {

          components: field_component_type {

            __typename

            ...sssParagraphSection

            ... on paragraph__title_and_description_components {

              id

              field_name

              field_title

              field_subtitle

              field_link {

                uri

                title

              }

              relationships {

                field_components {

                  ...sssParagraphTitleAndDescription

                }

              }

            }

          }

        }

      }

    }

  }

}

 

fragment sssParagraphSection on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...ParagraphHTMLText

      ...sssParagraphBanner

      ...sssParagraphTitleAndDescription

      ...sssParagraphImageWithTitleAndDescription

      ...sssParagraphSlideshowItem

    }

  }

}

 

fragment sssParagraphBanner on paragraph__banner {

  id

  title: field_title

  subtitle: field_subtitle

  bannerImage: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    bannerImage: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

}

 

fragment sssParagraphTitleAndDescription on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

  field_text {

    processed

  }

}

 

fragment ParagraphHTMLText on paragraph__html_text {

  id

  htmlText: field_text {

    processed

  }

}

 

fragment sssParagraphImageWithTitleAndDescription on paragraph__image_with_title_and_description {

  id

  title: field_title

  description: field_content {

    processed

  }

  image: field_image {

    alt

  }

  alignment: field_alignment

  relationships {

    image: field_image {

      id

      uri {

        value

        url

      }

    }

  }

}

 

fragment sssParagraphSlideshowItem on paragraph__slideshow_item {

  id

  image: field_image {

    alt

  }

  link: field_link {

    uri

    title

  }

  relationships {

    image: field_image {

      id

      uri {

        value

        url

      }

    }

  }

}
`
export default ShortStayService