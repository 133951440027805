import React,{ useState } from 'react'

const ParagraphBodyDynamicTab = (props) => {

    const { content } = props
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <div className="container aboutContainer">
                <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                    {typeof (content) !== "undefined" && content.map((el, index) => {
                        return (
                            <li role="presentation" className="nav-item" key={index}>
                                <a data-toggle="tab" role="tab" aria-selected="true"
                                    onClick={() => setActiveTab(index + 1)}
                                    className={`nav-link ${activeTab === index + 1 ? 'active' : ''}`}
                                    href={`#${el?.field_title}`}
                                    aria-controls={`${el?.field_title}`}
                                >
                                    {el?.field_title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="tab-content mt-3">
                {typeof (content) !== "undefined" && content.map((el, index) => {
                    const data = typeof (el?.text?.processed) !== "undefined" && el?.text?.processed.length > 0 ? el?.text?.processed : "";
                    return (
                        <div key={index} className={`tab-pane ${activeTab === index + 1 ? 'active' : ''}`} id={el?.field_title} role="tabpanel">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cardInnerWrap" dangerouslySetInnerHTML={{ __html: data }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default ParagraphBodyDynamicTab
